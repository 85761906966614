import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useCallback, useLayoutEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { isError } from "../lib/utils";
import classes from "./AsyncDialog.module.css";

export interface AsyncDialogProps<T> {
  cancelText: string;
  children: (props: { loading: boolean }) => React.ReactNode;
  onClose: () => void;
  onSubmit: () => Promise<unknown>;
  open: boolean;
  submitIcon: JSX.Element;
  submitText: string;
  title: string;
}

export function AsyncDialog<T>(props: AsyncDialogProps<T>) {
  const {
    cancelText,
    children,
    onClose,
    onSubmit,
    open,
    submitIcon,
    submitText,
    title,
  } = props;
  const [dialogTitleId] = useState(() => uuid());
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    if (open) {
      setError(undefined);
      setLoading(false);
    }
  }, [open]);

  const handleCancel = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  const handleSave = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      setError(undefined);
      setLoading(true);
      onSubmit()
        .then(() => {
          onClose();
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error: unknown) => {
          if (isError(error)) {
            setError(error);
          }

          console.error(error);
        });
    },
    [onClose, onSubmit],
  );

  return (
    <Dialog
      aria-labelledby={dialogTitleId}
      fullWidth={true}
      maxWidth="xs"
      onClose={handleCancel}
      open={open}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id={dialogTitleId}>{title}</DialogTitle>
        {typeof error !== "undefined" && (
          <DialogContent>
            <Typography color="error" variant="body2">
              {error.toString()}
            </Typography>
          </DialogContent>
        )}
        <DialogContent className={classes.content}>
          {children({ loading })}
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={loading} onClick={handleCancel}>
            {cancelText}
          </Button>
          <LoadingButton
            color="primary"
            loading={loading}
            loadingPosition="start"
            startIcon={submitIcon}
            type="submit"
            variant="contained"
          >
            {submitText}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
