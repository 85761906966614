import Edit from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncDialog } from "../../../../components/AsyncDialog";
import type { AccountRow } from "../AccountRow";
import { AccountActionsDelete } from "./AccountActionsDelete";
import classes from "./AccountActionsEdit.module.css";
import { AccountActionsWipe } from "./AccountActionsWipe";

interface AccountActionsEditProps {
  account: AccountRow;
  onChange: (account: AccountRow) => Promise<void>;
  onDelete: (account: AccountRow) => Promise<void>;
  onWipe: (account: AccountRow, reason: string) => Promise<void>;
}

export const AccountActionsEdit = (props: AccountActionsEditProps) => {
  const { account, onChange, onDelete, onWipe } = props;
  const { t } = useTranslation();

  const { name } = account;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<
    "delete" | "wipe" | undefined
  >(undefined);
  const [open, setOpen] = useState(false);
  const [pendingName, setPendingName] = useState(name);

  const handleAccountWipe = useCallback(() => {
    setConfirmDialogOpen("wipe");
  }, []);

  const handleAccountDelete = useCallback(() => {
    setConfirmDialogOpen("delete");
  }, []);

  const handleConfirmDialogClose = useCallback(() => {
    setConfirmDialogOpen(undefined);
  }, []);

  const handleClickOpen = useCallback(() => {
    setPendingName(name);

    setOpen(true);
  }, [name]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handlePendingNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPendingName(event.currentTarget.value);
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    return onChange({
      ...account,
      name: pendingName === "" ? undefined : pendingName,
    });
  }, [account, onChange, pendingName]);

  return (
    <>
      <span className={classes.root}>
        <Tooltip title={String(t("AccountActionsEdit.button"))}>
          <IconButton onClick={handleClickOpen} size="small">
            <Edit />
          </IconButton>
        </Tooltip>
      </span>
      <AsyncDialog
        cancelText={t("AccountActionsEdit.cancel")}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        submitIcon={<SaveIcon />}
        submitText={t("AccountActionsEdit.save")}
        title={t("AccountActionsEdit.title")}
      >
        {({ loading }) => (
          <>
            <TextField
              autoFocus
              disabled={loading}
              fullWidth
              label={t("AccountActionsEdit.name")}
              margin="normal"
              onChange={handlePendingNameChange}
              value={pendingName}
            />
            <Button
              color="warning"
              disabled={loading}
              onClick={handleAccountWipe}
              variant="contained"
            >
              {t("AccountActionsEdit.wipeAccount")}
            </Button>
            <Button
              color="error"
              disabled={loading}
              onClick={handleAccountDelete}
              variant="contained"
            >
              {t("AccountActionsEdit.deleteAccount")}
            </Button>
          </>
        )}
      </AsyncDialog>
      <AccountActionsDelete
        account={account}
        onClose={handleConfirmDialogClose}
        onDelete={onDelete}
        open={confirmDialogOpen === "delete"}
      />
      <AccountActionsWipe
        account={account}
        onClose={handleConfirmDialogClose}
        onWipe={onWipe}
        open={confirmDialogOpen === "wipe"}
      />
    </>
  );
};
